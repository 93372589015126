import React from "react"

import Layout from "../components/layout"
import cloudImage from "../images/skills/cloud.png"

const WhoamiPage = () => (
  <Layout whoamiTab={ "Whoami" }>
    <section className="content__body--mydescription">
      <div className="tagline">
          <h1>
              I have worked in several fields, but all with the same purpose, solve <strong>real problems</strong> with <strong>real people</strong>
          </h1>
      </div>
      <div className="intro">
          <h2>Who am I ??</h2>
          <p>
              I am a computer engineer who currently is working in London. I love technology and is where I spend most of
              my time, but also I love helping people and animals.
              <br/>
              <br/>
              Since I was a kid I have always loved medicine and computers. The very first time that I put together the pieces
              of a computer was when I was 7, since then I simply can't stop my curiosity for tech. Of course ,with time, I have
              realised that there are more areas that I like, and today I dedicate most of my time to software.
              <br/>
              <br/>
              Also I find fascinating the medicine field. I was raised by doctors and I guess is part of my DNA. One of my targets
              is to mix my computer experience and combine it with medicine to solve problems that one day will revolutionize
              and help a lot of people.
              <br/>
              <br/>
              But to summarize, I love problems that involves helping people and even more when I solve them...
          </p>
      </div>
      <div className="skills">
          <h2>Skills that I have</h2>
          <p>
              I spend a lot of time with different technologies, to solve different problems. Here I show a cloud showing how
              much time I'm spending on the technologies that I know:
          </p>
          <img src={ cloudImage } alt="Skills cloud"/>
          <p>
              This will vary and grow over time ...
          </p>
      </div>
    </section>
  </Layout>
)

export default WhoamiPage

